import axios from "../axios"

export default {
  getUsers () {
    return axios.get('/Admin/Users')
  },
  getUser (id) {
    return axios.get(`/Admin/Users/Id/${id}`)
  },
  getRoles () {
    return axios.get('/Admin/Roles')
  },
  getUserRoles (userId) {
    return axios.get(`/Admin/UserRoles/UserId/${userId}`)
  },
  addUserRole (userRole) {
    return axios.post('/Admin/UserRoles', userRole)
  },
  deleteUserRole (roleId) {
    return axios.delete(`/Admin/UserRoles/RoleId/${roleId}`)
  },
  logout () {
    localStorage.removeItem('roles')
    localStorage.removeItem('access_token')
    localStorage.removeItem('username')
    window.location.replace('/login')
  },
  hasCredentials () {
    const roles = localStorage.getItem('roles')
    if (roles === undefined) {
      return false
    }
    const token = localStorage.getItem('access_token')
    if (token === undefined) {
      return false
    }
    const username = localStorage.getItem('username')
    if (username === undefined) {
      return false
    }
    return true
  },
  isInRole (role) {
    const credentials = this.hasCredentials()
    let index = 0

    if (!credentials) {
      return false
    }

    const roles = localStorage.getItem('roles')

    if (typeof roles === 'string') {
      index = roles.indexOf(role)
    }

    if (index !== -1) {
      return true
    } else {
      return false
    }
  },
  getUserName () {
    const username = localStorage.getItem('username')
    if (username === undefined) {
      return ''
    } else {
      return username
    }
  }
}
