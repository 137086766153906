import Swal from 'sweetalert2'

export default {
    confirmDelete () {
        return Swal.fire({
            title: 'You won\'t be able to revert this!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            denyButtonText: `No, cancel!`,
        })
    },
    Success () {
        return Swal.fire({
            title: 'Success',
            timer: 1200,
            text: 'Work Saved'
        })
    }
}